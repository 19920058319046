import React from 'react'

type Props = {
  heading: string
  body: string
  buttonConfig?: {
    text: string
    onClick: () => void
  }
}

export const InfoCard = ({ heading, body, buttonConfig }: Props) => {
  return (
    <div className="bg-gray-100 h-screen w-screen">
      <div className="max-w-xl mx-auto py-12 sm:px-6 lg:px-8">
        <div className="bg-white m-5 shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{heading}</h3>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
              <div className="max-w-xl text-sm text-gray-500">
                <p>{body}</p>
              </div>
              {buttonConfig && (
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-karma-pink-700 hover:bg-karma-pink-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-karma-pink-500 sm:text-sm"
                    onClick={buttonConfig.onClick}>
                    {buttonConfig.text}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
